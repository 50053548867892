const getSortedRooms = (
  room_category: string,
  rooms: Array<any>
): Array<any> => {
  switch (room_category.toLowerCase()) {
    case "suites":
      const studioSuite = rooms.find(
        room => room.data.title.text.toLowerCase() === "studio"
      )
      const oneBedroomSuite = rooms.find(
        room => room.data.title.text.toLowerCase() === "one bedroom suite"
      )
      const twoBedroomSuite = rooms.find(
        room => room.data.title.text.toLowerCase() === "two bedroom suite"
      )
      const penthouseSuite = rooms.find(
        room => room.data.title.text.toLowerCase() === "penthouse suite"
      )
      const twoBedroomRooftopSuite841 = rooms.find(
        room => room.data.title.text.toLowerCase() === "two bedroom rooftop suite 841"
      )
      const twoBedroomRooftopSuite842 = rooms.find(
        room => room.data.title.text.toLowerCase() === "two bedroom rooftop suite 842"
      )
      return [studioSuite, oneBedroomSuite, twoBedroomSuite, penthouseSuite, twoBedroomRooftopSuite841, twoBedroomRooftopSuite842]

    case "lanai suites":
      const oneBedroomLanai = rooms.find(
        room => room.data.title.text.toLowerCase() === "one bedroom lanai suite"
      )
      const twoBedroomLanai = rooms.find(
        room => room.data.title.text.toLowerCase() === "two bedroom lanai suite"
      )
      const spaLanai = rooms.find(
        room => room.data.title.text.toLowerCase() === "spa lanai suite"
      )
      const royalLanai = rooms.find(
        room => room.data.title.text.toLowerCase() === "royal lanai suite"
      )
      const lanaiForOne = rooms.find(
        room => room.data.title.text.toLowerCase() === "lanai 41"
      )
      return [
        oneBedroomLanai,
        twoBedroomLanai,
        spaLanai,
        royalLanai,
        lanaiForOne,
      ].filter(room => room !== undefined)

    case "townhouse":
      return rooms
  }
  return rooms
}

export default getSortedRooms
